<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Achat de tokens via Automated Market Maker (AMM)</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Une personne désireuse d'acheter des tokrens en utilisant l'unité de compte de l'écosystème Fraction.re (le fcEURO)

			tout en ayant la garantie de recevoir immédiatement des tokens va émettre un ordre d'achat sur la place de marché automatique (AMM).

			<br /> <br />
			Le prix unitaire d'achat du token n'est pas défini à l'avance puisqu'il sera calculé automatiquement par le marché automatique
			afin de garantir la délivrance des tokens. Un nombre minimum de réception peut toutefois être défini afin de ne pas
			acheter à un prix trop élevé.





			<br /> <br />
			L'ordre d'achat est très simple à soumettre, il suffit d'indiquer le montant en fcEURO à acheter et valider.
			Il est aussi possible de définir un nombre minimum de réception de token.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Emma veut acheter pour 120 fcEURO de Berlcoin et avoir la garantie qu'au moins un Berlcoin lui soit directement crédité sur son compte.

			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/amm-1.png" />
				<br /> <br /> <b> Le compte de Emma</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre d'achat pour 120 fcEURO avec minimum de 1 Berlcoin</h3>


		<p>Dans la section <b>Achat</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/amm-2.png" />
				<br /> <br /> <b> Emma sélectionne le menu Achat et arrive sur la liste des tokens disponibles s'affiche </b><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/amm-3.png" /><br />
				<br /><b>Emma sélectionne le token Berlcoin, le menu des ordres s'affiche, elle clique sur ACHAT DIRECT (AMM) </b>
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/amm-4.png" /><br />
				<br /><b>Emma indique le montant de la transaction et le nombre minimum de Berlcoin à recevoir et valide. </b>
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/amm-5.png" /><br />
				<br /><b> Emma a reçu 1.20 Berlcoin, car l'AMM lui à permis de trouver des Berlcoin à 100 fcEURO. Elle a donc gagné 2.29% par rapport au cours de référence. </b>
			</div>


		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Nathalie a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre d'achat puis 0.03% du montant de la transaction (0.36 fcEURO)
				<br /> <br /> Au final moins de 4 centimes d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
